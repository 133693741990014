import * as React from "react"
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Page404 = () => {

  return (
    <Layout>
      <h1>Pagina no encontrada</h1>
      <p>
        <Link to="/">Volver</Link>.
      </p>
    </Layout>
  )
}

export default Page404;
